<template>
  <v-row
      align="center"
      class="ma-4"
      justify="center"
      v-show="!checkVersion"
  >
    <p>
      New version {{ newVersion }} is available!! please update
      &nbsp
      <span>
            <v-btn
                color="warning"
                variant="flat"
                size="small"
                :loading="isLoading"
                @click="cacheHandler()"
            >
              Update
            </v-btn>
          </span>
    </p>
  </v-row>
</template>

<script>
export default {
  name: "CacheComponent",
  data() {
    return {
      isLoading: false,
      newVersion: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    checkVersion() {
      const jsonVersion = localStorage.getItem('app-version');
      return this.newVersion === jsonVersion;
    },
  },
  methods: {
    cacheHandler() {
      this.isLoading = true;
      this.clearCache();
    },
    clearCache() {
      caches
          .keys()
          .then((keys) => Promise.all(keys.map((key) => caches.delete(key))))
          .finally(() => {
            this.isLoading = false;
            localStorage.setItem('app-version', this.newVersion);
            window.location.reload();
          });
    },
  }
}
</script>

<style scoped>

</style>
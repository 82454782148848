import AppHeader from "../layouts/AppHeader";
import AppFooter from "../layouts/AppFooter";

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        components: {
            header: AppHeader,
            default: () => import('../views/Dashboard'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/summary-reports',
        name: 'SummaryReports',
        components: {
            header: AppHeader,
            default: () => import('../views/SummaryReports'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {guestOnly: true}
    },
    {
        path: '/verify',
        name: 'Verify',
        component: () => import('../views/Verify'),
        meta: {guestOnly: true}
    },
    {
        path: '/extra-facilities',
        name: 'ExtraFacilities',
        components: {
            header: AppHeader,
            default: () => import('../views/ExtraFacilities'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/niche-categories',
        name: 'NicheCategories',
        components: {
            header: AppHeader,
            default: () => import('../views/NicheCategories.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/sites',
        name: 'Sites',
        components: {
            header: AppHeader,
            default: () => import('../views/Sites.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/users',
        name: 'Users',
        components: {
            header: AppHeader,
            default: () => import('../views/Usersss.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/profile',
        name: 'Profile',
        components: {
            header: AppHeader,
            default: () => import('../views/Profile.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/methods',
        name: 'Methods',
        components: {
            header: AppHeader,
            default: () => import('../views/Methods'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports',
        name: 'Reports',
        components: {
            header: AppHeader,
            default: () => import('../views/Reports'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice',
        name: 'Invoice',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/CreateInvoice'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/edit-invoice/:id',
        name: 'EditInvoice',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/EditInvoice'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice-dashboard',
        name: 'InvoiceDashboard',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/InvoiceDashboard'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoices',
        name: 'Invoices',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/Invoices'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice-mail-details/:id',
        name: 'InvoiceMailDetails',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/MailDetails'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice-details/:id',
        name: 'InvoiceDetails',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/InvoiceDetails'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice-migration',
        name: 'InvoicesMigration',
        components: {
            header: AppHeader,
            default: () => import('../views/InvoiceMigration'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },

    {
        path: '/live-link-replacement/:id',
        name: 'LiveLinkReplacement',
        components: {
            header: AppHeader,
            default: () => import('../views/LiveLinkReplacement'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/admins',
        name: 'Admins',
        components: {
            header: AppHeader,
            default: () => import('../views/AdminUsers'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/mail-templates',
        name: 'MailTemplates',
        components: {
            header: AppHeader,
            default: () => import('../views/MailTemplates'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/sale-counters',
        name: 'SaleCounters',
        components: {
            header: AppHeader,
            default: () => import('../views/sales-counters/SalesCounters'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/sale-counter',
        name: 'SaleCounter',
        components: {
            header: AppHeader,
            default: () => import('../views/sales-counters/SaleCounter'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/edit-sale-counter/:id',
        name: 'EditSaleCounter',
        components: {
            header: AppHeader,
            default: () => import('../views/sales-counters/EditSaleCounter'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/reseller-orders',
        name: 'ResellerOrdersReport',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/ResellerOrders'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/reseller-invoices',
        name: 'ResellerInvoicesReport',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/ResellerInvoices'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/sales-by-sites',
        name: 'SalesBySitesReport',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/SalesBySites'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/site-summary',
        name: 'siteSummary',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/SiteSummaryReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/payments',
        name: 'ReportPayments',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/Payments'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/fix-clients',
        name: 'FixClients',
        components: {
            header: AppHeader,
            default: () => import('../views/FixClients'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/broken-links',
        name: 'BrokenLinks',
        components: {
            header: AppHeader,
            default: () => import('../views/BrokenLinks'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/link-insertions',
        name: 'AllowedLinks',
        components: {
            header: AppHeader,
            default: () => import('../views/LinkInsertions'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/sale-counter-details/:id',
        name: 'SaleCounterDetails',
        components: {
            header: AppHeader,
            default: () => import('../views/sales-counters/SaleDetails'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/background-jobs',
        name: 'BackgroundJobs',
        components: {
            header: AppHeader,
            default: () => import('../views/BackgroundJobs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/mail-box',
        name: 'MailBox',
        components: {
            header: AppHeader,
            default: () => import('../views/MailBox'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/system-logs',
        name: 'SystemLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/SystemLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/task-scheduler',
        name: 'TaskScheduler',
        components: {
            header: AppHeader,
            default: () => import('../views/TaskScheduler'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/wp-access-control',
        name: 'WPAccessControl',
        components: {
            header: AppHeader,
            default: () => import('../views/WPAccess'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/site-logs',
        name: 'SiteLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/SiteLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/newsletter-logs',
        name: 'NewsletterLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/NewsLetterLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/queued-job-logs',
        name: 'QueuedJobLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/QueuedJobLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/delivery-email-logs',
        name: 'DeliveryEmailLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/DeliveryEmailLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/failed-queued-logs',
        name: 'FailedQueuedLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/FailedQueuedLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/duplicated-links-logs',
        name: 'DuplicatedLinksLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/DuplicatedLinksLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/order-delivery',
        name: 'OrderDelivery',
        components: {
            header: AppHeader,
            default: () => import('../views/OrderDelivery'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/client-summary',
        name: 'ClientSummary',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/ClientSummaryReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/sites-statement',
        name: 'SitesStatement',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/SitesStatement'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/sites-selling',
        name: 'SitesSellingReport',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/SitesSellingReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/profile/google-sheet-sync',
        name: 'GoogleSheetSync',
        components: {
            header: AppHeader,
            default: () => import('../views/GoogleSheetSync'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/site-owners',
        name: 'SiteOwners',
        components: {
            header: AppHeader,
            default: () => import('../views/partners/SiteOwners'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/site-ownerships',
        name: 'SiteOwnerships',
        components: {
            header: AppHeader,
            default: () => import('../views/partners/SiteOwnership'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/link-search',
        name: 'LinkSearch',
        components: {
            header: AppHeader,
            default: () => import('../views/LinkSearch'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/google/instant-checker',
        name: 'GoogleInstantChecker',
        components: {
            header: AppHeader,
            default: () => import('../views/google-indexer/InstantChecker'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/google/indexer',
        name: 'GoogleIndexer',
        components: {
            header: AppHeader,
            default: () => import('../views/google-indexer/IndexerPage'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/statements',
        name: 'StatementReports',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/Statement'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/change-admin-password',
        name: 'ChangeAdminPassword',
        components: {
            header: AppHeader,
            default: () => import('../views/change-admin-password'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/alfa-coins-currency',
        name: 'AlfaCoinsCurrency',
        components: {
            header: AppHeader,
            default: () => import('../views/Cryptocurrency'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/paypal',
        name: 'Paypal',
        components: {
            header: AppHeader,
            default: () => import('../views/Paypal'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/clients',
        name: 'Clients',
        components: {
            header: AppHeader,
            default: () => import('../views/ClientsPage'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/staffs',
        name: 'StaffsReports',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/StaffReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/systems-sync',
        name: 'SystemsSync',
        components: {
            header: AppHeader,
            default: () => import('../views/SystemSync'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/duplicate-order-list',
        name: 'DuplicateOrderList',
        components: {
            header: AppHeader,
            default: () => import('../views/OrderReplica'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    }
]

export default routes;

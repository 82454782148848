import reportHandler from "../../services/Report";

export default {
    async loadDSummaries({commit}, payload) {
        return reportHandler.dSummary(payload);
    },
    async loadInvoiceByDate({commit}, payload) {
        return reportHandler.invoiceByDate(payload);
    },
    async loadPayments({commit, dispatch}, payload) {
        try {
            const {data: {data: {items}}} = await reportHandler.payments(payload);

            commit('SET_PAYMENTS', items);
        } catch (error) {
            commit('SET_PAYMENTS', []);
        }
    },
    async downloadRabbiITPaymentAsPDF({commit, dispatch}, payload) {
        return reportHandler.downloadPaymentPDF(payload);
    }
}

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import auth from "./auth";
import user from "./user";
import extraFacility from "./extra-facility";
import nicheCategory from "./niche-category";
import method from "./method";
import site from "./site";
import invoice from "./invoice";
import mailTemplate from "./mail-template";
import report from "./report";
import salesCounter from "./sales-counter";
import brokenLink from "./broken-link";
import linkInsertion from "./link-insertion";
import mailBox from "./mail-box";
import newsLetter from "./news-letter";
import taskScheduler from "./task-scheduler";
import wpAccess from "./wp-access";
import siteLog from "./site-logs";
import newsletterLog from "./newsletter-logs";
import queuedJobLogs from "./queued-job-logs";
import failedQueuedLogs from "./failed-queued-logs";
import orderDeliveryEmail from "./order-delivery-email";
import deliveryEmailLog from "./delivery-email-logs";
import clientSummary from "./client-summary";
import googleSheet from "./google-sheet-sync";
import siteOwner from "./site-owner";
import siteOwnership from "./site-ownership";
import statement from "./statement";
import cryptocurrency from "./cryptocurrency";
import sitesStatement from "./sites-statement";
import client from "./client";
import staff from "./staff";
import duplicatedLinksLog from "./duplicated-links-log";
import orderReplica from "./order-replica";
import indexer from "./indexer";



export default new Vuex.Store({
    plugins: [createPersistedState({ paths: ["auth"] })],
    modules: {
        user,
        auth,
        extraFacility,
        nicheCategory,
        site,
        method,
        invoice,
        mailTemplate,
        report,
        salesCounter,
        brokenLink,
        linkInsertion,
        mailBox,
        newsLetter,
        taskScheduler,
        wpAccess,
        siteLog,
        newsletterLog,
        queuedJobLogs,
        failedQueuedLogs,
        orderDeliveryEmail,
        deliveryEmailLog,
        clientSummary,
        googleSheet,
        siteOwner,
        siteOwnership,
        statement,
        cryptocurrency,
        sitesStatement,
        client,
        staff,
        duplicatedLinksLog,
        orderReplica,
        indexer
    },
    state: {
        snackBar: {},
        showLoading: false,
        dynamicWidgets: []
    },
    mutations: {
        SET_SNACKBAR: function (state, payload) {
            state.snackBar = payload
        },
        SET_LOADING: function (state, payload) {
            state.showLoading = payload
        },
        SET_DYNAMIC_WIDGETS: function (state, payload) {
            state.dynamicWidgets = payload
        }
    },
})

import Api from './Api';

const dSummary = (payload) => {
    const path = '/dashboard-summary';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const invoiceByDate = (payload) => {
    const path = '/invoice-by-date';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const payments = (payload) => {
    const path = '/payment/reports';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const downloadPaymentPDF = (payload) => {
    const path = '/payment/reports/pdf';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

export default {
    dSummary,
    invoiceByDate,
    payments,
    downloadPaymentPDF
};

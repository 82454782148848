import Api from './Api';

const all = (payload) => {
    const path = '/sales-counter';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const salesReportBySite = (payload) => {
    const path = '/sales-counter/sales-report-by-site';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const siteSummaryReports = (payload) => {
    const path = '/sales-counter/site-summary-reports';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const search = (payload) => {
    const path = '/search';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/sales-counter/store',
        data: payload
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/sales-counter/update',
        method: 'PUT',
        data: payload
    });
};

const remove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/sales-counter/delete/${payload.id}`,
    });
};

const postRemove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/sales-counter/post-remove/${payload.id}`,
    });
};

const removeBulkPosts = (payload) => {
    return Api.postRequest({
        endpoint: '/sales-counter/bulk-post-remove',
        method: 'PUT',
        data: payload
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/sales-counter/destroy/${payload.id}`,
    });
};

const get = (payload) => {
    return Api.getRequest({
        endpoint: `/sales-counter/get-by-sales-counter-id/${payload.id}`,
    });
};

const counterDetails = (payload) => {
    return Api.getRequest({
        endpoint: `/sales-counter/get-by-sales-counter-details/${payload.id}`,
    });
};

const restore = (payload) => {
    return Api.postRequest({
        endpoint: '/sales-counter/restore',
        method: 'PUT',
        data: payload
    });
};

const republish = (payload) => {
    return Api.postRequest({
        endpoint: '/sales-counter/republish',
        method: 'PUT',
        data: payload
    });
};

const replacement = (payload) => {
    return Api.postRequest({
        endpoint: '/sales-counter/replaced-live-link',
        method: 'PUT',
        data: payload
    });
};

const getAllEmail = (payload) => {
    const path = '/sales-counter/get-all-email';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const prepaymentStatus = (payload) => {
    return Api.postRequest({
        endpoint: '/sales-counter/prepayment-status',
        method: 'PUT',
        data: payload
    });
}

const deleteWithoutPost = (payload) => {
    return Api.deleteRequest({
        endpoint: `/sales-counter/delete-without-post/${payload.id}`,
    });
};

const getAllUnreadRepublish = (payload) => {
    const path = '/sales-counter/get-all-unread-messages';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const republishMarkAsRead = (payload) => {
    return Api.postRequest({
        endpoint: '/sales-counter/mark-as-read',
        method: 'PUT',
        data: payload
    });
};

const deleteBulkPosts = (payload) => {
    debugger
    return Api.postRequest({
        endpoint: '/sales-counter/bulk-post-delete',
        method: 'PUT',
        data: payload
    });
};

export default {
    all,
    store,
    update,
    remove,
    postRemove,
    destroy,
    get,
    search,
    restore,
    counterDetails,
    republish,
    replacement,
    getAllEmail,
    salesReportBySite,
    siteSummaryReports,
    prepaymentStatus,
    deleteWithoutPost,
    getAllUnreadRepublish,
    republishMarkAsRead,
    removeBulkPosts,
    deleteBulkPosts
};

import Api from './Api';

const all = (payload) => {
    const path = '/clients';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};
const get = (payload) => {
    const path = '/client/get';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/client/store',
        data: payload
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/client/update',
        method: 'PUT',
        data: payload
    });
};

const remove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/client/delete/${payload.id}`,
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/client/destroy/${payload.id}`,
    });
};

export default {
    all,
    store,
    update,
    remove,
    destroy,
    get
};

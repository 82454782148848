import clientHandler from "../../services/Clients";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data : {data}} = await clientHandler.all(payload);
            commit('SET_CLIENTS', data);
        } catch (error) {
            commit('SET_CLIENTS', []);
        }
    },
    async store({commit, dispatch}, payload) {
        return clientHandler.store(payload);
    },
    async get({commit, dispatch}, payload) {
        return clientHandler.get(payload);
    },
    async update({commit, dispatch}, payload) {
        return clientHandler.update(payload);
    },
    async remove({commit, dispatch}, payload) {
        return clientHandler.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return clientHandler.destroy(payload);
    },
}
